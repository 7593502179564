enum screen {
  MAIN,
  ERROR,
}
enum errors {
  NOT_FOUND = 1,
  INIT_DATA = 2
}
export {
  screen,
  errors
}