import './css/styles.css';
import './scripts/types/interfaces';
import ReactDOM from 'react-dom/client';
import App from './scripts/App';

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        viewportHeight: number;
        viewportStableHeight: number;
        ready: () => void;
        expand: () => void;
        close: () => void;
        themeParams: {
          bg_color: string;
          text_color: string;
          hint_color: string;
          link_color: string;
          button_color: string;
          button_text_color: string;
          secondary_bg_color: string;
        },
        initData: string;
        initDataUnsafe: {
          user: {
            id: number;
            first_name: string;
            last_name: string;
            username: string;
            language_code: string;
          }
        }
      };
    }
  }
}

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(<App />);