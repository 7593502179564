import { makeAutoObservable } from 'mobx';

class User {
  constructor() {
    makeAutoObservable(this);
  }

  private _initData: string;
  private _id: number;
  private _username: string;
  private _loaded: boolean;
  
  public getInitData(): string {
    return this._initData;
  }

  public setInitData(data: string): void {
    this._initData = data;
  }

  public setID(id: number): void {
    this._id = id;
  }

  public getID(): number {
    return this._id;
  }

  public setUsername(username: string): void {
    this._username = username;
  }

  public getUsername(): string {
    return this._username;
  }

  public setLoaded(): void {
    this._loaded = true;
  }

  public isLoaded(): boolean {
    return this._loaded;
  }
}

export default new User();