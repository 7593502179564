import axios from 'axios';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from './types/enums';
import State from './data/State';
import User from './data/User';

import Main from './screens/Main';
import Error from './screens/Error';

const init = () => {
  const telegram = window.Telegram.WebApp;
  telegram.ready();
  telegram.expand();

  try { User.setInitData(telegram.initData); }
  catch (e) { User.setInitData(''); }
  try { User.setID(telegram.initDataUnsafe.user.id); }
  catch (e) { User.setID(0); }
  try { User.setUsername(telegram.initDataUnsafe.user.username); }
  catch (e) { User.setUsername(''); }
  
  axios.post(process.env.REACT_APP_API + '/getData', {
    init_data: User.getInitData(),
    id: User.getID(),
    username: User.getUsername()
  }).then(res => {
    if (!res.data.error) {

    } else {
      State.setError(res.data.error_type);
      State.setScreen(screen.ERROR);
    }
    User.setLoaded();
  }).catch(e => State.setScreen(screen.ERROR));
}

export default observer(() => {
  useEffect(() => init(), []);
  if (!User.isLoaded()) return;
  return State.getScreen() === screen.MAIN ? <Main /> :
    State.getScreen() === screen.ERROR ? <Error /> :
    null;
});