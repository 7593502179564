import { makeAutoObservable } from 'mobx';
import { screen } from '../types/enums';

class State {
  constructor() {
    makeAutoObservable(this);
  }

  private _screen: screen = screen.MAIN;
  private _error: number;

  public getScreen(): screen {
    return this._screen;
  }

  public setScreen(screen: screen): void {
    const root = document.querySelector('#root');
    root.scrollTop = 0;
    this._screen = screen;
  }

  public getError(): number {
    return this._error;
  }

  public setError(error: number): void {
    this._error = error;
  }
}

export default new State();